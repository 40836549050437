import { useRemoteGet, useWindowContext } from "@/hooks";
import {
  CustomAmrMachinePageResourceService,
  CustomDeviceResourceService,
  CustomReportPageResourceService,
  CustomSitesResourceService,
} from "@/services/openapi";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Reports.module.css";
import Label from "@/components/UI/Label";
import { useTranslation } from "react-i18next";
import FilterBar from "@/components/Filter/Filterbar";
import Button from "@/components/UI/Button";
import CloseIcon from "../../assets/images/closeIcon.svg";
import Box from "@/components/Box";
import LineCharts from "@/components/UI/Chart/LineCharts";
import BarChart from "@/components/UI/Chart/BarChart";
import downloadIcon from "../../assets/images/download.svg";
import Maps from "@/components/UI/Maps";
import ReportActivity from "@/components/ReportActivity";
import HorizontalReportActivity from "@/components/ReportActivity/HorizontalReportActivity";
import ReportAssists from "@/components/ReportAssists";
import ReportMachine from "@/components/ReportMachine";
import MainContext from "@/contexts/MainContext";
import moment from "moment-timezone";
import { modelData } from "@/deviceModels";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formDateToTimestamp } from "@/utils";
import { SelectInstance, SingleValue } from "react-select";
import { SelectDataProps } from "@Ui/Select/SelectBox";
import { useFilterDateContext } from "@/hooks/FilterDateContext/useFilterDateContext";
import ButtonMobile from "@/components/UI/ButtonMobile";
import machine_active_mobile from "@Images/icon_machine.svg";
import calendar_active_mobile from "@Images/icon_calendar.svg";
import sites_active_mobile from "@Images/Icon_point.svg";
import Img from "@/components/UI/Img";
import { BarChartProps } from "@/components/UI/Chart/BarChart/BarChart";
import { LineChartsProps } from "@/components/UI/Chart/LineCharts/LineCharts";
import Collapse from "@/components/UI/Collapse";
import CustomLoader from "@/components/UI/CustomLoader";
import { ComboSelectProps } from "@/components/UI/Select/ComboSelect/ComboSelect";

const machinesPerPage = 15;
const Reports = () => {
  const titleCtx = useContext(MainContext);

  useEffect(() => {
    titleCtx.setTitle(
      <Label text={t("sidebar.reports")} className={styles.titleLabel} />
    );
  }, []);

  const [downloadReport, setDownloadReport] = useState<boolean>(false);
  const { size, isMobile } = useWindowContext();
  const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);

  const { t } = useTranslation();

  const modelRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [model, setModel] = useState<string>("All");
  const [modelIsUserTriggered, setModelIsUserTriggered] =
    useState<boolean>(false);

  const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [site, setSite] = useState<string>("All");
  const [siteIsUserTriggered, setSiteIsUserTriggered] =
    useState<boolean>(false);

  const { date1, date2, setDate1, setDate2, timezone } = useFilterDateContext();
  const date1InputRef = useRef(null);
  const date2InputRef = useRef(null);

  const [bestPerformingIsOpen, setBestPerformingIsOpen] =
    useState<boolean>(true);
  const [underPerformingIsOpen, setUnderPerformingIsOpen] =
    useState<boolean>(true);

  const sitesData = useRemoteGet({
    remoteMethod: () => {
      if (model !== "All") {
        return CustomSitesResourceService.getSitesByDeviceModel(model);
      } else {
        //if(typeof fundingRequest.data?.id)
        // return CustomSitesResourceService.getAllSites1();
        //return CustomSitesResourceService.getAllSites1();
        return CustomSitesResourceService.getAllSitesOfACertainOrigin('Brain');
      }
    },
    lazy: false,
  });

  const amrMachineSitesCount = useRemoteGet({
    remoteMethod: () => {
      //if(typeof fundingRequest.data?.id)
      // return CustomSitesResourceService.getAllSites1();
      return CustomAmrMachinePageResourceService.amrMachinePageCountDevice();
    },
    lazy: false,
  });

  const deviceData = useRemoteGet({
    remoteMethod: () => {
      if (site !== "All") {
        return CustomDeviceResourceService.getDeviceModelBySite(parseInt(site));
      }
    },
    lazy: false,
  });

  const allMachines = useMemo(() => {
    let countMachines = 0;
    amrMachineSitesCount.data?.forEach((site) => {
      countMachines = countMachines + (site?.allMachine ?? 0);
    });
    return countMachines;
  }, [amrMachineSitesCount.data]);

  // Update sites filter when model change
  useEffect(() => {
    if (modelIsUserTriggered) {
      sitesData.fetch();
    }
  }, [model]);

  // Update models filter when site change
  useEffect(() => {
    if (siteIsUserTriggered) {
      deviceData.fetch();
    }
  }, [site]);

  useEffect(() => {
    if (siteIsUserTriggered && site === "All" && model === "All") {
      setSiteIsUserTriggered(false);
    }
  }, [site, model]);

  const setSitesOptionSelected = () => {
    let data: SelectDataProps = { value: "All", label: "All" };
    if (site !== "All" && sitesData.data) {
      const sites = sitesData.data.find((sites) => sites.id === parseInt(site));
      data = {
        value: sites?.id?.toString() ?? "",
        label: sites?.siteName ?? "",
      };
    }
    return data;
  };

  const setModelOptions = useCallback(() => {
    return (
      modelData.map((val) => {
        return { value: val.key ?? "", label: val.model ?? "" };
      }) ?? []
    );
  }, []);

  const selectSites: ComboSelectProps = {
    selectData:
      sitesData.data?.map((val) => {
        return { value: "" + val.id ?? "", label: val.siteName ?? "" };
      }) ?? [],
    textLabel: t("sidebar.sites"),
    nameSelect: "selectSites",
    imgSrc: (size.width as number) > 900 ? undefined : sites_active_mobile,
    firstOption: "All",
    ref: siteRef,
    valueSelected: setSitesOptionSelected(),
    isUserTriggered: siteIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setSiteIsUserTriggered(isUserTriggered);
      setModelIsUserTriggered(false);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (isUserTriggered && singleValue?.value === "All") {
        setModel("All");
      }
      if (singleValue !== null && actionMeta.action === "select-option") {
        setSite(singleValue.value);
      }
    },
  };

  const selectModel: ComboSelectProps = {
    selectData: setModelOptions(),
    textLabel: t("dashboard.model"),
    nameSelect: "selectModel",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    firstOption: "All",
    ref: modelRef,
    valueSelected: { value: model, label: model },
    isUserTriggered: modelIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setModelIsUserTriggered(isUserTriggered);
      // setSiteIsUserTriggered(false);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setModel(singleValue.value);
      }
    },
  };

  const comboDate = {
    idDate1: "data1",
    nameDate1: "Data1",
    valueDate1: date1,
    idDate2: "data2",
    nameDate2: "Data2",
    valueDate2: date2,
    min: "string",
    max: "string",
    labelName: "string",
    textLabel: t("dashboard.period"),
    imgSrc: (size.width as number) > 900 ? undefined : calendar_active_mobile,
    ref1: date1InputRef,
    onChangeDate1: (e: any) => {
      setDate1(e.target.value);
    },
    ref2: date2InputRef,
    onChangeDate2: (e: any) => {
      setDate2(e.target.value);
    },
  };

  const totalDaysMachineUtilizationData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getTotalDayMachinesUtilization(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const dailyAvgTotalRunData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getDeilyAvgTotalRun(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const dailyAvgAutoData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getDeilyAvgAutonomus(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const dailyAvgManualData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getDeilyAvgManual(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const avgTrainingData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getAvgTraining(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const avgAssistData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getAvgAssist(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const dailyPercAutoData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getDeilyPercAutonomus(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const autoUsagePercOverviewData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getAutoUsagePercentageOverview(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const autoUsagePercOverviewChartData = [
    {
      name: Object.keys(autoUsagePercOverviewData.data ?? [])[0] ?? "",
      uv: Object.values(autoUsagePercOverviewData.data ?? [])[0] ?? 0,
    },
    {
      name: Object.keys(autoUsagePercOverviewData.data ?? [])[1] ?? "",
      uv: Object.values(autoUsagePercOverviewData.data ?? [])[1] ?? 0,
    },
    {
      name: Object.keys(autoUsagePercOverviewData.data ?? [])[2] ?? "",
      uv: Object.values(autoUsagePercOverviewData.data ?? [])[2] ?? 0,
    },
    {
      name: Object.keys(autoUsagePercOverviewData.data ?? [])[3] ?? "",
      uv: Object.values(autoUsagePercOverviewData.data ?? [])[3] ?? 0,
    },
    {
      name: Object.keys(autoUsagePercOverviewData.data ?? [])[4] ?? "",
      uv: Object.values(autoUsagePercOverviewData.data ?? [])[4] ?? 0,
    },
    {
      name: Object.keys(autoUsagePercOverviewData.data ?? [])[5] ?? "",
      uv: Object.values(autoUsagePercOverviewData.data ?? [])[5] ?? 0,
    },
  ];

  const assistPercData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getAssistPerc(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const assistPerc = Object.keys(assistPercData.data ?? {}).map(
    (key, index) => {
      return {
        id: index,
        name: key,
        value: Object.values(assistPercData.data ?? [])[index] ?? 0,
      };
    }
  );

  const assistPageOverviewData = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getAssistReportPageOverview(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        site !== "All" ? parseInt(site) : undefined,
        model !== "All" ? model : undefined
      );
    },
    lazy: false,
  });

  const assistPageOverviewChartData =
    assistPageOverviewData.data?.length !== 0
      ? assistPageOverviewData.data?.map((obj) => {
          return { name: obj.date, uv: obj.value, pv: obj.valuePrec };
        }) ?? [{ name: "", uv: 0, pv: 0 }]
      : [{ name: "", uv: 0, pv: 0 }];

  const performingTableDataBest = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getTableReportPerforming(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        true,
        undefined,
        undefined
      );
    },
    lazy: false,
  });

  const performingTableDataWorst = useRemoteGet({
    remoteMethod: () => {
      return CustomReportPageResourceService.getTableReportPerforming(
        formDateToTimestamp(date1),
        formDateToTimestamp(date2, true),
        timezone,
        false,
        undefined,
        undefined
      );
    },
    lazy: false,
  });

  const [maxYValue, setMaxYValue] = useState<number>(100);

  useEffect(() => {
    setMaxYValue(
      Math.max(...autoUsagePercOverviewChartData.map((val) => val.uv))
    );
  }, [autoUsagePercOverviewChartData]);

  const barChart1 = {
    data: autoUsagePercOverviewChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthBarC: 400,
    heightBarC: 230,
    barSize: 20,
    dataKey: "name",
    top: 30,
    right: 25,
    left: 0,
    bottom: 0,
    vertical: true,
    maxValue: maxYValue,
    labelX: t("report.autoUsagePercentage"),
    labelY: t("report.nMachines"),
  };
  const barChart1Mobile: BarChartProps = {
    data: autoUsagePercOverviewChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthBarC: (size.width as number) - 60,
    heightBarC: 250,
    barSize: 20,
    dataKey: "name",
    top: 30,
    right: 15,
    left: -35,
    bottom: 20,
    vertical: true,
    fontSizeYAxis: 8,
    maxValue: maxYValue,
    labelX: t("report.autoUsagePercentage"),
    labelY: t("report.nMachines"),
  };

  const lineChart1 = {
    id: "assistOverview",
    data: assistPageOverviewChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: 400,
    heightLineC: 200,
    dataKey: "name",
    top: 30,
    right: 25,
    left: -20,
    bottom: 20,
    color: "#09556F",
    labelX: t("chart.date"),
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };
  const lineChart1Mobile: LineChartsProps = {
    id: "assistOverview",
    data: assistPageOverviewChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: size.width as number,
    heightLineC: 250,
    dataKey: "name",
    top: 20,
    right: 40,
    left: 0,
    bottom: 25,
    color: "#09556F",
    fontSizeYAxis: 11,
    labelX: t("chart.date"),
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };

  const handleBestPerf = () => {
    setBestPerformingIsOpen(!bestPerformingIsOpen);
  };

  const handleUnderPerf = () => {
    setUnderPerformingIsOpen(!underPerformingIsOpen);
  };

  const handleResetFilters = () => {
    setDate1(moment().subtract(8, "days").format("YYYY-MM-DD"));
    setDate2(moment().subtract(1, "days").format("YYYY-MM-DD"));
    setModelIsUserTriggered(true);
    setModel("All");
    setSite("All");
  };

  const [errorDataPeriod, setErrorDataPeriod] = useState(false);

  const applyFilters = () => {
    // console.log("MODEL: ", model, "\nSITE: ", site);
    if (date1 <= date2) {
      setErrorDataPeriod(false);
      if (!totalDaysMachineUtilizationData.loading)
        totalDaysMachineUtilizationData.fetch();
      if (!dailyAvgTotalRunData.loading) dailyAvgTotalRunData.fetch();
      if (!dailyAvgAutoData.loading) dailyAvgAutoData.fetch();
      if (!dailyAvgManualData.loading) dailyAvgManualData.fetch();
      if (!avgTrainingData.loading) avgTrainingData.fetch();
      if (!avgAssistData.loading) avgAssistData.fetch();
      if (!dailyPercAutoData.loading) dailyPercAutoData.fetch();
      if (!autoUsagePercOverviewData.loading) autoUsagePercOverviewData.fetch();
      if (!assistPercData.loading) assistPercData.fetch();
      if (!assistPageOverviewData.loading) assistPageOverviewData.fetch();
      if (!performingTableDataBest.loading) performingTableDataBest.fetch();
      if (!performingTableDataWorst.loading) performingTableDataWorst.fetch();
    } else {
      setErrorDataPeriod(true);
      setTimeout(() => {
        setErrorDataPeriod(false);
      }, 2000);
    }
  };

  let pdfPages = 1;
  const tableElements: JSX.Element[][] = [];
  if (
    performingTableDataBest.data &&
    performingTableDataBest.data.length > 0
    // && performingTableDataBest.data.length > machinesPerPage
  ) {
    pdfPages = Math.ceil(performingTableDataBest.data.length / machinesPerPage);

    for (let pageIndex = 0; pageIndex < pdfPages; pageIndex++) {
      const rowElement: JSX.Element[] = [];
      let lastIndex: number;
      if (pageIndex === pdfPages - 1) {
        lastIndex = performingTableDataBest.data.length;
      } else {
        lastIndex = (pageIndex + 1) * machinesPerPage;
      }

      for (
        let index = pageIndex * machinesPerPage;
        index < lastIndex;
        index++
      ) {
        const val = performingTableDataBest.data[index];
        rowElement.push(
          <ReportMachine
            rank={index + 1}
            rin={val.rin ?? ""}
            key={index}
            site={val.siteName ?? ""}
            autoDays={"" + val.autoDay ?? ""}
            autoHoursDay={val.autoUsage ?? ""}
            autoUsage={val.autoUsagePerc?.toFixed(0) + "%" ?? "0%"}
            m2cleaned={"" + val.m2Cleaned ?? ""}
            totalUsage={val.totalUsage ?? ""}
            manualHoursDay={val.manualUsage ?? ""}
            totalAssist={val.totAssist ?? 0}
            distanceAuto={"" + val.distanceAuto ?? ""}
            performance={"best"}
            benchmark={val.benchmarkRatingDTO}
          />
        );
      }
      tableElements.push(rowElement);
    }
  }

  const renderTable = tableElements.map((val, index) => {
    return (
      <div
        id={`printReportDocumentPageTable-${index}`}
        className={styles.pdf_page}
      >
        {val}
      </div>
    );
  });

  const handleReportDownload = useCallback(async () => {
    const to_hide = document.getElementsByClassName(styles.to_hide_in_report);
    try {
      setDownloadReport(true);
      for (const toHideElement of to_hide) {
        toHideElement.classList.add(styles.hidden);
      }
      const domElementPage1 = document.getElementById(
        "printReportDocumentPage1"
      );
      const canvas1 = await html2canvas(domElementPage1!, {
        scale: 1.5,
      });
      const page1 = canvas1.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(page1, "PNG", 7.5, 15, 210 - 15, 297 - 65);

      for (let page = 0; page < pdfPages; page++) {
        pdf.addPage();
        const domElementPage2 = document.getElementById(
          `printReportDocumentPageTable-${page}`
        );
        const canvas2 = await html2canvas(domElementPage2!, {
          scale: 1.5,
        });
        const page2 = canvas2.toDataURL("image/png");
        if (page === pdfPages - 1) {
          const number = tableElements[tableElements.length - 1].length;
          const pageElementLength = (267 / machinesPerPage) * number;
          pdf.addImage(page2, "PNG", 7.5, 15, 210 - 15, pageElementLength);
        } else {
          pdf.addImage(page2, "PNG", 7.5, 15, 210 - 15, 297 - 30);
        }
      }

      pdf.setDrawColor("#f2f6f6");
      pdf.save("Report.pdf");
    } catch (e) {
      console.error("Download Report Error: ", e);
      setDownloadReport(false);
    } finally {
      for (const toHideElement of to_hide) {
        toHideElement.classList.remove(styles.hidden);
      }
      setDownloadReport(false);
    }
  }, [pdfPages, tableElements]);

  const loader = () => {
    if (
      totalDaysMachineUtilizationData.loading ||
      dailyAvgTotalRunData.loading ||
      dailyAvgAutoData.loading ||
      dailyAvgManualData.loading ||
      avgTrainingData.loading ||
      avgAssistData.loading ||
      dailyPercAutoData.loading ||
      autoUsagePercOverviewData.loading ||
      assistPercData.loading ||
      assistPageOverviewData.loading ||
      performingTableDataBest.loading ||
      performingTableDataWorst.loading
    )
      return (
        <span>
          <CustomLoader searchLoader={true} />
        </span>
      );
    else return <span></span>;
  };

  return (
    <>
      <div className={styles.container}>
        <div id="printReportDocumentPage1" className={styles.pdf_page}>
          {isMobile && (
            <div className={styles.title_container_header}>
              <Label text={t("sidebar.reports")} className={""} />
            </div>
          )}

          <div className={styles.horizontalContainer}>
            {/*--------------------------Filters Desktop START --------------------------------------------------------------*/}
            {!isMobile && (
              <div className={styles.filterbar_main_container}>
                <div className={styles.filterBar_container}>
                  <div className={styles.filterBar_header}>
                    <Label
                      text={t("AmrMachines.filters")}
                      className={styles.filterLabel}
                    />
                    <Button
                      name={t("AmrMachines.resetFilters")}
                      className={styles.resetFilters_btn}
                      iconStart={CloseIcon}
                      iconStartClassName={styles.resetFiltersIcon}
                      onClick={handleResetFilters}
                    />
                  </div>
                  <div className={styles.filterBar_body}>
                    <FilterBar
                      filters={[
                        {
                          type: "Date",
                          selectData: comboDate,
                          boxClassName: styles.box_period,
                          dataError: errorDataPeriod,
                        },
                        {
                          type: "Select",
                          selectData: selectModel,
                          boxClassName: styles.select_container,
                        },
                        {
                          type: "Select",
                          selectData: selectSites,
                          boxClassName: styles.select_container,
                        },
                      ]}
                    />
                  </div>
                  {/*<div className={styles.filterBar_footer}>*/}
                  {/*    <Checkbox name={'comparePeriod'} textLable={t('report.comparePreviousPeriod')}*/}
                  {/*              classNameLable={styles.filterBar_footerLabel}/>*/}

                  {/*</div>*/}
                </div>
              </div>
            )}
            {/*------------------------------Filters Desktop END --------------------------------------------------------------*/}

            {/*---------------- FILTER MOBILE START -------------------------*/}

            {isMobile && (
              <div className={styles.filterBar_container}>
                <div className={styles.filterBar_header}>
                  {/* <Label text={t('AmrMachines.filters')} className={styles.filterLabel}/> */}
                  <div className={styles.filter_mobile}>
                    <ButtonMobile
                      text={t("assist.filters")}
                      onClick={() => {
                        setShowFiltersPage(!showFiltersPage);
                      }}
                      iconStart="filtersIcon.svg"
                    />
                    {/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
                  </div>
                </div>
              </div>
            )}
            {/*----------------------------FILTER MOBILE END--------------------------------------------*/}

            {!isMobile && (
              <Box
                className={styles.downloadContainer}
                children={
                  <>
                    <Label
                      text={t("report.downloadReport")}
                      className={styles.downloadLabel}
                    />
                    {!downloadReport && (
                      <Button
                        name={t("report.download")}
                        className={styles.downloadBtn}
                        onClick={handleReportDownload}
                        iconStart={downloadIcon}
                      />
                    )}
                    {downloadReport && (
                      <button className={styles.downloadBtn}>
                        <div className={styles.loading_wheel} />
                      </button>
                    )}
                  </>
                }
              />
            )}
            {/* {isMobile &&
                    <div className={styles.downloadBtn}>
                        <ButtonMobile text={t('report.downloadReport')} onClick={handleReportDownload} iconStart='download-blue.svg' />
                    </div>
                } */}
          </div>
          {/*---------------- FILTER MOBILE COLLAPSED START -------------------------*/}
          {isMobile && (
            <div
              className={
                showFiltersPage
                  ? styles.filterBar_body
                  : styles.filterBar_body_hide
              }
            >
              <FilterBar
                filters={[
                  {
                    type: "Date",
                    selectData: comboDate,
                    boxClassName: styles.box_period,
                    dataError: errorDataPeriod,
                  },
                  {
                    type: "Select",
                    selectData: selectModel,
                    boxClassName: styles.select_container,
                  },
                  {
                    type: "Select",
                    selectData: selectSites,
                    boxClassName: styles.select_container,
                  },
                ]}
              />
              <Button
                name={t("AmrMachines.resetFilters")}
                className={styles.resetFilters_btn}
                iconStart={CloseIcon}
                iconStartClassName={styles.resetFiltersIcon}
                onClick={handleResetFilters}
              />
            </div>
          )}
          {/*---------------- FILTER MOBILE COLLAPSED END -------------------------*/}

          <div className={styles.searchBtnContainer}>
            <Button
              name={t("dashboard.applyFilters")}
              className={styles.searchBtn}
              onClick={applyFilters}
            />
            {loader()}
          </div>

          {isMobile && (
            <div className={styles.labelAllMachineContainer}>
              <Img
                alt=""
                className={styles.iconLabelAllMachine}
                src={machine_active_mobile}
              />
              <Label
                className={styles.labelAllMachine}
                text={`Total Machine: ${allMachines}`}
              />
            </div>
          )}
          <div
            className={[styles.map_container, styles.to_hide_in_report].join(
              " "
            )}
          >
            <Maps className={styles.map} sites={sitesData.data ?? []} />
          </div>

          <Label
            text={t("report.activityOverview")}
            className={styles.titleLabel}
          />

          <div
            className={
              isMobile ? styles.verticalContainer : styles.horizontalContainer
            }
          >
            <ReportActivity
              title={t("report.totalDaysMachineUtilization")}
              currValue={
                typeof totalDaysMachineUtilizationData.data?.currPeriod ===
                "undefined"
                  ? "0"
                  : "" + totalDaysMachineUtilizationData.data?.currPeriod
              }
              prevValue={
                typeof totalDaysMachineUtilizationData.data?.prevPeriod ===
                "undefined"
                  ? "0"
                  : "" + totalDaysMachineUtilizationData.data?.prevPeriod
              }
              benchmarkValue={
                typeof totalDaysMachineUtilizationData.data?.benchmarkRatingDTO
                  ?.value === "undefined"
                  ? "00:00"
                  : "" +
                    totalDaysMachineUtilizationData.data?.benchmarkRatingDTO
                      ?.value
              }
              score={
                typeof totalDaysMachineUtilizationData.data?.score ===
                "undefined"
                  ? 0
                  : totalDaysMachineUtilizationData.data?.score
              }
            />
            <ReportActivity
              title={t("report.avgRunTime")}
              currValue={
                typeof dailyAvgTotalRunData.data?.currPeriod === "undefined"
                  ? "00:00"
                  : "" + dailyAvgTotalRunData.data?.currPeriod
              }
              prevValue={
                typeof dailyAvgTotalRunData.data?.prevPeriod === "undefined"
                  ? "00:00"
                  : "" + dailyAvgTotalRunData.data?.prevPeriod
              }
              benchmarkValue={
                typeof dailyAvgTotalRunData.data?.benchmarkRatingDTO?.value ===
                "undefined"
                  ? "00:00"
                  : "" + dailyAvgTotalRunData.data?.benchmarkRatingDTO?.value
              }
              score={
                typeof dailyAvgTotalRunData.data?.score === "undefined"
                  ? 0
                  : dailyAvgTotalRunData.data?.score
              }
              trend={"down"}
            />
            <ReportActivity
              title={t("report.avgAutonomousUsage")}
              currValue={
                typeof dailyAvgAutoData.data?.currPeriod === "undefined"
                  ? "00:00"
                  : "" + dailyAvgAutoData.data?.currPeriod
              }
              prevValue={
                typeof dailyAvgAutoData.data?.currPeriod === "undefined"
                  ? "00:00"
                  : "" + dailyAvgAutoData.data?.currPeriod
              }
              benchmarkValue={
                typeof dailyAvgAutoData.data?.benchmarkRatingDTO?.value ===
                "undefined"
                  ? "00:00"
                  : "" + dailyAvgAutoData.data?.benchmarkRatingDTO?.value
              }
              score={
                typeof dailyAvgAutoData.data?.score === "undefined"
                  ? 0
                  : dailyAvgAutoData.data?.score
              }
              trend={"down"}
            />
          </div>

          <div
            className={
              isMobile ? styles.verticalContainer : styles.horizontalContainer
            }
            style={{ marginBottom: "4%" }}
          >
            <HorizontalReportActivity
              title={t("report.dailyAvgManualUsage")}
              currValue={
                typeof dailyAvgManualData.data?.currPeriod === "undefined"
                  ? "00:00"
                  : "" + dailyAvgManualData.data?.currPeriod
              }
              prevValue={
                typeof dailyAvgManualData.data?.prevPeriod === "undefined"
                  ? "00:00"
                  : "" + dailyAvgManualData.data?.prevPeriod
              }
              benchmarkValue={
                typeof dailyAvgManualData.data?.benchmarkRatingDTO?.value ===
                "undefined"
                  ? "0"
                  : "" + dailyAvgManualData.data?.benchmarkRatingDTO?.value
              }
              score={
                typeof dailyAvgManualData.data?.score === "undefined"
                  ? 0
                  : dailyAvgManualData.data?.score
              }
              trend={"down"}
            />
            <HorizontalReportActivity
              title={t("report.avgTrainingTime")}
              currValue={
                typeof avgTrainingData.data?.currPeriod === "undefined"
                  ? "00:00"
                  : "" + avgTrainingData.data?.currPeriod
              }
              prevValue={
                typeof avgTrainingData.data?.prevPeriod === "undefined"
                  ? "00:00"
                  : "" + avgTrainingData.data?.prevPeriod
              }
              trend={"up"}
            />
            <HorizontalReportActivity
              title={t("report.avgAssistPerDay")}
              currValue={
                typeof avgAssistData.data?.currPeriod?.toFixed(0) ===
                "undefined"
                  ? "0"
                  : "" + avgAssistData.data?.currPeriod?.toFixed(0)
              }
              prevValue={
                typeof avgAssistData.data?.prevPeriod?.toFixed(0) ===
                "undefined"
                  ? "0"
                  : "" + avgAssistData.data?.prevPeriod?.toFixed(0)
              }
              benchmarkValue={
                typeof avgAssistData.data?.benchmarkRatingDTO?.value ===
                "undefined"
                  ? "0"
                  : "" + avgAssistData.data?.benchmarkRatingDTO?.value
              }
              score={
                typeof avgAssistData.data?.score === "undefined"
                  ? 0
                  : avgAssistData.data?.score
              }
              trend={"down"}
            />
          </div>

          {/*------------------------OVERVIEW PERFORMANCE START--------------------------------------*/}
          {!isMobile && (
            <Label
              text={t("report.performanceOverview")}
              className={styles.titleLabel}
            />
          )}

          <Collapse title={t("report.performanceOverview")}>
            <div
              className={
                isMobile ? styles.verticalContainer : styles.horizontalContainer
              }
              style={{ width: "80%", marginBottom: "4%" }}
            >
              <ReportActivity
                title={t("report.autoUsage")}
                currValue={
                  typeof dailyPercAutoData.data?.currPeriod?.toFixed(0) ===
                  "undefined"
                    ? "0%"
                    : "" + dailyPercAutoData.data?.currPeriod?.toFixed(0) + "%"
                }
                prevValue={
                  typeof dailyPercAutoData.data?.prevPeriod?.toFixed(0) ===
                  "undefined"
                    ? "0%"
                    : "" + dailyPercAutoData.data?.prevPeriod?.toFixed(0) + "%"
                }
                benchmarkValue={
                  typeof dailyPercAutoData.data?.benchmarkRatingDTO?.value ===
                  "undefined"
                    ? "0%"
                    : "" +
                      dailyPercAutoData.data?.benchmarkRatingDTO?.value +
                      "%"
                }
                score={
                  typeof dailyPercAutoData.data?.score === "undefined"
                    ? 0
                    : dailyPercAutoData.data?.score
                }
              />
              <Box
                className={styles.box_grafico}
                children={
                  <div className={styles.box_chart}>
                    <div className={styles.title_chart_container}>
                      <Label
                        text={t("report.autoUsagePercentage")}
                        className={styles.title_chart}
                      />
                      {/*<Select name={''} selectData={[{value: "Last Month", text: "Last Month"}]}*/}
                      {/*        style={styles.selectOverview}/>*/}
                    </div>
                    <div className={styles.chart}>
                      <BarChart {...(isMobile ? barChart1Mobile : barChart1)} />
                    </div>
                  </div>
                }
              />
            </div>
          </Collapse>
          {/*------------------------OVERVIEW PERFORMANCE END--------------------------------------*/}

          {/*------------------------Assist PERFORMANCE START--------------------------------------*/}

          {!isMobile && (
            <Label text={t("report.assist")} className={styles.titleLabel} />
          )}
          <Collapse title={t("report.assist")}>
            <div
              className={
                isMobile ? styles.verticalContainer : styles.horizontalContainer
              }
              style={{ width: "90%", marginBottom: "4%" }}
            >
              <ReportAssists data={assistPerc ?? []} />

              <Box
                className={styles.box_grafico}
                children={
                  <div className={styles.box_chart}>
                    <div className={styles.title_chart_container}>
                      <Label
                        text={t("assist.assistOverview")}
                        className={styles.title_chart}
                      />
                      {/*<Select name={''} selectData={[{value: "Last Month", text: "Last Month"}]}*/}
                      {/*        style={styles.selectOverview}/>*/}
                    </div>
                    <div className={styles.chart}>
                      <LineCharts
                        {...(isMobile ? lineChart1Mobile : lineChart1)}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          </Collapse>
        </div>
        {/*------------------------Assist PERFORMANCE START--------------------------------------*/}

        {!isMobile && (
          <div className={styles.button_main_container}>
            <div className={styles.button_container}>
              <Button
                name={t("sites.bestPerforming")}
                className={styles.outlined_green}
                onClick={() => {
                  handleBestPerf();
                }}
              />
              <Button
                name={t("sites.underPerforming")}
                className={styles.outlined_orange}
                onClick={() => {
                  handleUnderPerf();
                }}
              />
            </div>
          </div>
        )}

        {bestPerformingIsOpen && (
          <>
            <Label
              text={t("report.bestPerforming")}
              className={styles.titleLabel}
            />
            <div className={styles.verticalContainerMachineTable}>
              {renderTable}
            </div>
          </>
        )}

        {underPerformingIsOpen && (
          <>
            <Label
              text={t("report.underPerforming")}
              className={styles.titleLabel}
            />

            <div className={styles.verticalContainerMachineTable}>
              {performingTableDataWorst.data?.map((val, index) => (
                <ReportMachine
                  rank={index + 1}
                  rin={val.rin ?? ""}
                  key={index}
                  site={val.siteName ?? ""}
                  autoDays={"" + val.autoDay ?? ""}
                  autoHoursDay={val.autoUsage ?? ""}
                  autoUsage={val.autoUsagePerc?.toFixed(0) + "%" ?? "0%"}
                  m2cleaned={"" + val.m2Cleaned ?? ""}
                  totalUsage={val.totalUsage ?? ""}
                  manualHoursDay={val.manualUsage ?? ""}
                  totalAssist={val.totAssist ?? 0}
                  distanceAuto={"" + val.distanceAuto ?? ""}
                  performance={"under"}
                  benchmark={val.benchmarkRatingDTO}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Reports;
