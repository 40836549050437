export const modelData = [
    {
        key: "t7",
        model: "T7AMR"
    },
    {
        key: "t380",
        model: "T380AMR"
    },
    {
        key: "t16",
        model: "T16AMR"
    },
]
