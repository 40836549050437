import Table from '@/components/UI/Table/Table';
import { useRemoteGet, useWindowContext } from '@/hooks';
import {
	CustomDeviceResourceService,
	CustomRoutesResourceService,
	CustomSitesPageResourceService,
	CustomSitesResourceService,
	CustomToolsenseMachinePageResourceService
} from '@/services/openapi';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import Maps from '@/components/UI/Maps';
import { ComboSelectProps } from '@/components/UI/Select/ComboSelect/ComboSelect';
import { useTranslation } from 'react-i18next';
import calendar_active from '@Images/calendar_filter.svg';
import sites_active from '@Images/sites_filter.svg';
import DoubleFilterBar from '@/components/Filter/DoubleFilterBar';
import styles from './Sites.module.css';
import Button from '@Ui/Button';
import bestPerformingIcon from '../../assets/images/whiteBestPerforming.svg';
import underPerformingIcon from '../../assets/images/whiteUnderPerforming.svg';
import TableTitle from '@/components/TableTitle';
import downloadIcon from '../../assets/images/download.svg';
import Label from '@/components/UI/Label';
import MainContext from '@/contexts/MainContext';
import { PagingContextProvider } from '@/contexts/providers/PagingContextProvider';
import { modelData } from '@/deviceModels';
import DownloadButton from '@/components/UI/DownloadButton';
import { formDateToTimestamp } from '@/utils';
import ButtonMobile from '@/components/UI/ButtonMobile';
import calendar_active_mobile from '@Images/icon_calendar.svg';
import machine_active_mobile from '@Images/icon_machine.svg';
import sites_active_mobile from '@Images/Icon_point.svg';
import TableMobile from '@/components/UI/Table/TableMobile';
import { SelectInstance, SingleValue, MultiValue } from 'react-select';
import { SelectDataProps } from '@Ui/Select/SelectBox';
import { useFilterDateContext } from '@/hooks/FilterDateContext/useFilterDateContext';
import CustomLoader from '@Ui/CustomLoader';
import { RoutesDTO } from '@/services/openapi';
import { useParams } from 'react-router-dom';

const Sites = () => {
	const { size, isMobile } = useWindowContext();

	const titleCtx = useContext(MainContext);

	const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);
	const { t } = useTranslation();

	const [showFilterValue, setShowFilterValue] = useState<boolean>(true);

	const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [site, setSite] = useState<string>('All');
	const [siteIsUserTriggered, setSiteIsUserTriggered] = useState<boolean>(false);

	const routeRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [route, setRoute] = useState<string>('All');
	const [routeIsUserTriggered, setRouteIsUserTriggered] =
		useState<boolean>(false);

	const modelRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [model, setModel] = useState<string>('All');
	const [modelIsUserTriggered, setModelIsUserTriggered] =
		useState<boolean>(false);

	const { origin = "AMR" } = useParams<{ origin: string }>();
	const [forceOriginUpdate, setForceOriginUpdate] =
		useState<boolean>(false);

	const rinRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [multipleRin, setMultipleRin] = useState<string[]>([]);
	const [multipleRinIsUserTriggered, setMultipleRinIsUserTriggered] =
		useState<boolean>(false);

	const { date1, date2, setDate1, setDate2, timezone } = useFilterDateContext();
	const date1InputRef = useRef(null);
	const date2InputRef = useRef(null);

	const [bestPerformingIsOpen, setBestPerformingIsOpen] =
		useState<boolean>(true);
	const [underPerformingIsOpen, setUnderPerformingIsOpen] =
		useState<boolean>(true);

	const sitesData = useRemoteGet({
		remoteMethod: () => {
			// const routeId = isNaN(parseInt(route)) ? undefined : parseInt(route);
			// return CustomSitesResourceService.getSitesByMultipleFilters(
			//   routeId,
			//   model,
			//   multipleRin
			// );
			// return CustomSitesResourceService.getAllSites1();
			return CustomSitesResourceService.getAllSitesOfACertainOrigin(origin);
		},
		lazy: false
	});

	const routesData = useRemoteGet({
		remoteMethod: () => {
			const siteId = isNaN(parseInt(site)) ? undefined : parseInt(site);
			return CustomRoutesResourceService.getRoutesByMultipleFilters(
				siteId,
				model,
				multipleRin.length === 0 ? ['All'] : multipleRin
			);
		},
		lazy: false
	});

	const devicesData = useRemoteGet({
		remoteMethod: () => {
			const routeId = isNaN(parseInt(route)) ? undefined : parseInt(route);
			const siteId = isNaN(parseInt(site)) ? undefined : parseInt(site);
			if (siteIsUserTriggered && siteId) {
				return CustomDeviceResourceService.getAllDevicesBySiteId(siteId);
			}

			return CustomDeviceResourceService.getDevicesByMultipleFilters(
				origin,
				siteId,
				routeId,
				model,
				multipleRin.length === 0 ? ['All'] : multipleRin
			);
		},
		lazy: false
	});

	const deviceModelsData = useRemoteGet({
		remoteMethod: () => {
			return CustomToolsenseMachinePageResourceService.toolsenseMachineModel(
				'Toolsense',
				site !== 'All' ? parseInt(site) : undefined
			);
		},
		lazy: false
	});

	useEffect(() => {
		if (origin) {
			setForceOriginUpdate((prev) => !prev); // Toggle to force a new fetch
			sitesData.fetch();
			routesData.fetch();
			devicesData.fetch();
			deviceModelsData.fetch();
		}
	}, [origin]);

	useEffect(() => {
		// Reset forceTypeMachineUpdate after data fetch
		if (forceOriginUpdate) {
			sitesData.fetch();
			routesData.fetch();
			devicesData.fetch();
			deviceModelsData.fetch();
			setForceOriginUpdate(false);
		}
	}, [forceOriginUpdate]);

	useEffect(() => {
		sitesData.fetch();
		routesData.fetch();
		devicesData.fetch();
	}, []);

	// Update filters when site changes
	useEffect(() => {
		if (siteIsUserTriggered) {
			devicesData.fetch();
			routesData.fetch();
		}
	}, [site]);

	// Update filters when route changes
	useEffect(() => {
		if (routeIsUserTriggered) {
			devicesData.fetch();
		}
	}, [route]);

	// Update filters when machine model changes
	useEffect(() => {
		//sitesData.fetch();
		//routesData.fetch();
		if (modelIsUserTriggered) {
			devicesData.fetch();
		}
	}, [model]);

	useEffect(() => {
		if (
			siteIsUserTriggered &&
			site === 'All' &&
			model === 'All' &&
			route === 'All' &&
			multipleRin.length === 0
		) {
			setSiteIsUserTriggered(false);
		}
	}, [site, route, model, multipleRin]);

	const comboDate = {
		idDate1: 'data1',
		nameDate1: 'Data1',
		valueDate1: date1,
		idDate2: 'data2',
		nameDate2: 'Data2',
		valueDate2: date2,
		min: 'string',
		max: 'string',
		labelName: 'string',
		textLabel: t('dashboard.period'),
		imgSrc:
			(size.width as number) > 900 ? calendar_active : calendar_active_mobile,
		ref1: date1InputRef,
		onChangeDate1: (e: any) => {
			setDate1(e.target.value);
		},
		ref2: date2InputRef,
		onChangeDate2: (e: any) => {
			setDate2(e.target.value);
		}
	};

	const setSitesOptionsSelected = useCallback(
		(site: string) => {
			let data: SelectDataProps = { value: 'All', label: 'All' };

			const updateDataBySite = () => {
				const sites = sitesData.data?.find((sites) => sites.id === parseInt(site));
				data = {
					value: sites?.id?.toString() ?? '',
					label: sites?.siteName ?? ''
				};
				return data;
			};

			if (siteIsUserTriggered) {
				if (site === 'All') {
					return data;
				} else {
					return updateDataBySite();
				}
			} else {
				if (multipleRin.length > 1) {
					return data;
				} else if (
					multipleRin.length === 1 &&
					!multipleRin.includes('All') &&
					sitesData.data &&
					devicesData.data
				) {
					const device = devicesData.data.find(
						(device) => device.rin === multipleRin[0]
					);
					const sites = sitesData.data.find((sites) => sites.id === device?.siteId);
					data = {
						value: sites?.id?.toString() ?? '',
						label: sites?.siteName ?? ''
					};
					return data;
				} else if (site !== 'All') {
					return updateDataBySite();
				}
			}

			return data;
		},
		[devicesData.data, multipleRin, sitesData.data]
	);

	const setRoutesOptions = useCallback(() => {
		let data: SelectDataProps[] = [];

		const defaultData = () => {
			return (
				routesData.data?.map((val) => {
					return {
						value: '' + val.id ?? '',
						label: `${val.routeName ?? ''}${
							val.routeLabel ? ` - ${val.routeLabel}` : ''
						}`
					};
				}) ?? []
			);
		};

		if (
			!siteIsUserTriggered &&
			multipleRin.length === 1 &&
			!multipleRin.includes('All') &&
			sitesData.data &&
			devicesData.data
		) {
			const device = devicesData.data.find(
				(device) => device.rin === multipleRin[0]
			);
			const sites = sitesData.data.find((sites) => sites.id === device?.siteId);
			const routes: RoutesDTO[] =
				routesData.data?.filter((route) => route.siteId === sites?.id) ?? [];
			routes.map((route) => {
				data.push({
					value: '' + route.id ?? '',
					label: `${route.routeName ?? ''}${
						route.routeLabel ? ` - ${route.routeLabel}` : ''
					}`
				});
			});
		} else {
			return defaultData();
		}
		return data;
	}, [routesData.data]);

	const setSelectedRoute = useCallback(
		(route: string) => {
			let data: SelectDataProps = { value: 'All', label: 'All' };
			if (routesData.data && route !== 'All') {
				const routes = routesData.data.find((r) => r.id === parseInt(route));
				data = {
					value: routes?.id?.toString() ?? '',
					label: routes?.routeName?.concat(' - ' + routes.routeLabel) ?? ''
				};
			}
			return data;
		},
		[routesData.data]
	);

	const setModelOptions = useCallback(() => {
		switch (origin) {
			case 'Toolsense':
				return (
					deviceModelsData.data?.map((val) => {
						return { value: val, label: val };
					}) ?? []
				);
			// case "Conventional": ...
			//   break;
			default: // AMR
				return (
					modelData.map((val) => {
						return { value: val.key ?? '', label: val.model ?? '' };
					}) ?? []
				);
		}
	}, [deviceModelsData.data, origin]);

	const selectSites: ComboSelectProps = {
		selectData:
			sitesData.data?.map((val) => {
				return { value: '' + val.id ?? '', label: val.siteName ?? '' };
			}) ?? [],
		textLabel: t('sidebar.sites'),
		nameSelect: 'selectSites',
		imgSrc: (size.width as number) > 900 ? sites_active : sites_active_mobile,
		firstOption: 'All',
		ref: siteRef,
		valueSelected: setSitesOptionsSelected(site),
		isUserTriggered: siteIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setSiteIsUserTriggered(isUserTriggered);
			setMultipleRinIsUserTriggered(false);
			setModelIsUserTriggered(false);
			setRouteIsUserTriggered(false);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (isUserTriggered && singleValue?.value === 'All') {
				setModel('All');
				setMultipleRin([]);
			}
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setSite(singleValue.value);
			}
		}
	};

	const selectRoute: ComboSelectProps = {
		selectData: setRoutesOptions(),
		textLabel: t('dashboard.route'),
		nameSelect: 'selectRoute',
		imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
		firstOption: 'All',
		ref: routeRef,
		valueSelected: setSelectedRoute(route),
		isUserTriggered: routeIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setRouteIsUserTriggered(isUserTriggered);
			setSiteIsUserTriggered(false);
			// setTypeIsUserTriggered(!isUserTriggered);
			// setMultipleRinIsUserTriggered(!isUserTriggered);
			// setModelIsUserTriggered(!isUserTriggered);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setRoute(singleValue.value);
			}
		}
	};

	const selectModel: ComboSelectProps = {
		selectData: setModelOptions(),
		textLabel: t('dashboard.model'),
		nameSelect: 'selectModel',
		imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
		firstOption: 'All',
		ref: modelRef,
		valueSelected: { value: model, label: model },
		isUserTriggered: modelIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setModelIsUserTriggered(isUserTriggered);
			// setSiteIsUserTriggered(!isUserTriggered);
			// setRouteIsUserTriggered(!isUserTriggered);
			// setTypeIsUserTriggered(!isUserTriggered);
			// setMultipleRinIsUserTriggered(!isUserTriggered);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setModel(singleValue.value);
			}
		}
	};

	const selectRin: ComboSelectProps = {
		selectData:
			devicesData.data?.map((val) => {
				return { value: '' + val.rin ?? '', label: val.rin ?? '' };
			}) ?? [],
		textLabel: t('dashboard.rin'),
		nameSelect: 'selectRin',
		imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
		ref: rinRef,
		multiValueSelected: multipleRin.map((rin) => {
			return { value: rin, label: rin };
		}),
		isMulti: true,
		isUserTriggered: multipleRinIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setMultipleRinIsUserTriggered(isUserTriggered);
			setSiteIsUserTriggered(false);
			// setRouteIsUserTriggered(!isUserTriggered);
			setModelIsUserTriggered(false);
			// setTypeIsUserTriggered(!isUserTriggered);

			const multiValue = newValue as MultiValue<SelectDataProps>;
			if (multiValue !== null) {
				if (multiValue.length === 0) {
					setMultipleRin([]);
				} else {
					const deviceValues = multiValue.map((item) => item.value);
					setMultipleRin(deviceValues);
					if (deviceValues.length === 1 && devicesData.data) {
						const device = devicesData.data.find(
							(device) => device.rin === deviceValues[0]
						);
						if (device && model !== device.deviceModel) {
							setModel(device.deviceModel!);
						}
					}
				}
			}
		}
	};

	const [errorDataPeriod, setErrorDataPeriod] = useState(false);

	const filterbar = {
		filters: [
			{
				type: 'Date',
				selectData: comboDate,
				boxClassName: styles.box_period,
				dataError: errorDataPeriod
			},
			{
				type: 'Select',
				selectData: selectSites,
				boxClassName: styles.box_sites
			},
			{
				type: 'ButtonFilter',
				textButton: '-',
				selectData: selectSites,
				boxClassName: styles.box_reset_btn
			}
		],
		showFilter: showFilterValue,
		showFilterCallback: () => {
			setShowFilterValue(!showFilterValue);
		}
	};

	/* FILTER BAR CREATION SECTION */

	const baseFilter = {
		type: 'Select',
		boxClassName: styles.boxContainer1
	};

	let filters: any[];

	switch (origin) {
		case 'Toolsense':
			filters = [
				{ ...baseFilter, selectData: selectModel },
				// { ...baseFilter, selectData: selectRin }
			];
			break;
		// case "Conventional":
		//   filters = [
		//     ...
		//   ];
		//   break;
		default: // AMR
			filters = [
				{ ...baseFilter, selectData: selectRoute },
				{ ...baseFilter, selectData: selectModel },
				{ ...baseFilter, selectData: selectRin }
			];
	}

	const filterbar1 = {
		filters: filters
	};

	/* FILTER BAR MOBILE CREATION SECTION */

	const baseFilterMobile = {
		type: 'Select',
		boxClassName: styles.boxContainer1
	};

	let filterbarMobile = {
		filters: [
			{
				type: 'Date',
				selectData: comboDate,
				boxClassName: styles.box_period,
				dataError: errorDataPeriod
			},
			{
				type: 'Select',
				selectData: selectSites,
				boxClassName: styles.box_sites
			}
		]
	};

	switch (origin) {
		case 'Toolsense':
			filterbarMobile.filters = [
				{ ...baseFilterMobile, selectData: selectModel },
				// { ...baseFilterMobile, selectData: selectRin }
			];
			break;
		// case "Conventional":
		//   filtersMobile = [
		//     ...
		//   ];
		//   break;
		default: // AMR
			filterbarMobile.filters = [
				{ ...baseFilterMobile, selectData: selectRoute },
				{ ...baseFilterMobile, selectData: selectModel },
				{ ...baseFilterMobile, selectData: selectRin }
			];
	}

	const handleBestPerf = () => {
		setBestPerformingIsOpen(!bestPerformingIsOpen);
	};

	const handleUnderPerf = () => {
		setUnderPerformingIsOpen(!underPerformingIsOpen);
	};

	const colonneAMR = {
		sitesId: t('columns.sites.sitesId'),
		sites: t('columns.sites.sites'),
		totalUsagePerDay: t('columns.sites.totalUsagePerDay'),
		autonomusUsage: t('columns.sites.autonomusUsage'),
		assistPerDay: t('columns.sites.assistPerDay'),
		manualHours: t('columns.sites.manualHours'),
		trainingHours: t('columns.sites.trainingHours'),
		score: t('columns.sites.score')
		// benchmark: t('columns.sites.benchmark')
	};

	const colonneToolsense = {
		sitesId: t('columns.sites.sitesId'),
		sites: t('columns.sites.sites'),
		address: t('machine.toolsense.address'),
		totalUtilization: t('columns.sites.totalUtilization'),
		totalWorkDone: t('columns.sites.totalWorkDone'),
		totalRuntime: t('columns.sites.totalRuntime'),
		score: t('columns.sites.score')
	};

	useEffect(() => {
		if (sitesData.data) {
			titleCtx.setTitle(
				<>
					<Label
						text={'' + sitesData.data.length + ' '}
						className={styles.titleLabelBlue}
					/>
					<Label text={t('sidebar.sites')} className={styles.titleLabel} />
				</>
			);
		}
	}, [sitesData]);

	const buttonRef = useRef<HTMLButtonElement>(null);

	const applyFilters = useCallback(() => {
		// console.log("TIPO: ", type, "\nSITE: ", site, "\nROUTE: ", route, "\nMODEL: ", model, "\nRIN: ", multipleRin);
		if (date1 <= date2) {
			setErrorDataPeriod(false);
			if (sitesData.data) {
				titleCtx.setTitle(
					<>
						<Label
							text={'' + sitesData.data.length + ' '}
							className={styles.titleLabelBlue}
						/>
						<Label text={t('sidebar.sites')} className={styles.titleLabel} />
					</>
				);
			}
			// routesData.fetch();
			// devicesData.fetch();
			if (!deviceModelsData.loading) deviceModelsData.fetch();
		} else {
			setErrorDataPeriod(true);
			setTimeout(() => {
				setErrorDataPeriod(false);
			}, 2000);
		}
	}, [date1, date2, sitesData, routesData, devicesData, model]);

	const loader = () => {
		if (sitesData.loading || routesData.loading || devicesData.loading || deviceModelsData.loading)
			return (
				<span>
					<CustomLoader searchLoader={true} />
				</span>
			);
		else return <span></span>;
	};

	const applyFiltersButton = useMemo(() => {
		return (
			<div className={styles.searchBtnContainer}>
				<Button
					ref={buttonRef}
					name={t('dashboard.applyFilters')}
					className={styles.searchBtn}
					onClick={applyFilters}
				/>
				{loader()}
			</div>
		);
	}, [applyFilters, buttonRef]);

	return (
		<>
			<div className={styles.container}>
				{isMobile && (
					<div className={styles.title_container_header}>
						<Label
							text={sitesData.data?.length + ' '}
							className={styles.title_labelColored_header}
						/>
						<Label text={t('sidebar.sites')} className={''} />
					</div>
				)}
				{/*-------------------FILTRI Mobile START---------------------------------- */}
				{isMobile && (
					<>
						<div className={styles.filter_mobile}>
							<ButtonMobile
								text={t('assist.filters')}
								onClick={() => {
									setShowFiltersPage(!showFiltersPage);
								}}
								iconStart="filtersIcon.svg"
							/>
							{/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
						</div>
						<div
							className={
								showFiltersPage
									? styles.filter_mobile_page
									: styles.filter_mobile_page_hide
							}
						>
							<DoubleFilterBar filterBarData={filterbarMobile} />
						</div>
					</>
				)}

				{/*------------------FILTRI Mobile END------------------------------------- */}

				{/*-------------------FILTRI DESKTOP START---------------------------------- */}
				{!isMobile && (
					<div className={styles.select_container}>
						<DoubleFilterBar filterBarData={filterbar} />
						{showFilterValue && <DoubleFilterBar filterBarData={filterbar1} />}
					</div>
				)}
				{/*------------------FILTRI DESKTOP END------------------------------------- */}

				{applyFiltersButton}

				<div className={styles.map_container}>
					<Maps className={styles.map} sites={sitesData.data ?? []} />
				</div>

				{!isMobile && (
					<div className={styles.button_main_container}>
						<div className={styles.button_container}>
							<Button
								name={t('sites.bestPerforming')}
								className={styles.outlined_green}
								onClick={() => {
									handleBestPerf();
								}}
							/>
							<Button
								name={t('sites.underPerforming')}
								className={styles.outlined_orange}
								onClick={() => {
									handleUnderPerf();
								}}
							/>
						</div>
					</div>
				)}

				<div
					className={styles.table_container}
					style={bestPerformingIsOpen ? {} : { display: 'none' }}
				>
					<div className={styles.table_title_header}>
						<TableTitle
							color="green"
							titleText={t('sites.bestPerforming')}
							// firstButtonText={t('sites.compareBestSites')} secondButtonText={t('sites.download')}
							titleIcon={bestPerformingIcon}
							//secondButtonIcon={downloadIcon}
						/>
						<DownloadButton
							name={!isMobile ? t('sites.download') : ''}
							className={styles.downloadBtn}
							iconStart={downloadIcon}
							filename={'best_performing_machines.csv'}
							columns={origin === 'AMR' ? colonneAMR : colonneToolsense}
							remoteMethod={() => {
								if (origin === 'AMR') {
									return CustomSitesPageResourceService.sitePageTableUnpaged(
										formDateToTimestamp(date1),
										formDateToTimestamp(date2, true),
										timezone,
										origin,
										true,
										site !== 'All' ? parseInt(site!) : undefined,
										multipleRin.includes('All') ? undefined : multipleRin,
										model !== 'All' ? model : undefined,
										route !== 'All' ? parseInt(route!) : undefined
									);
								} else {
									return CustomSitesPageResourceService.toolsenseSitePageTableUnpaged(
										formDateToTimestamp(date1),
										formDateToTimestamp(date2, true),
										timezone,
										origin,
										true,
										site !== 'All' ? parseInt(site!) : undefined,
										multipleRin.includes('All') ? undefined : multipleRin,
										model !== 'All' ? model : undefined,
										route !== 'All' ? parseInt(route!) : undefined
									);
								}
							}}
						/>
					</div>
					<div className={styles.table_subContainer}>
						<PagingContextProvider
							searchRef={buttonRef}
							queryParams={{
								date1,
								date2,
								type: origin,
								site,
								rin: multipleRin,
								model,
								route
							}}
							forceTrigger={forceOriginUpdate}
							triggerRemoteGet={true}
							remoteMethod={(queryParams, paging) => {
								return () => {
									if (origin === 'AMR') {
										return CustomSitesPageResourceService.sitePageTable(
											formDateToTimestamp(queryParams.date1!),
											formDateToTimestamp(queryParams.date2!, true),
											timezone,
											queryParams.type ?? 'AMR',
											true,
											queryParams.site !== 'All' ? parseInt(queryParams.site!) : undefined,
											queryParams.rin?.includes('All') ? queryParams.rin : undefined,
											queryParams.model !== 'All' ? queryParams.model : undefined,
											queryParams.route !== 'All'
												? parseInt(queryParams.route!)
												: undefined,
											paging.pageNumber,
											paging.pageSize,
											paging.sortBy,
											paging.sortOrder
										);
									} else {
										return CustomSitesPageResourceService.toolsenseSitePageTable(
											formDateToTimestamp(queryParams.date1!),
											formDateToTimestamp(queryParams.date2!, true),
											timezone,
											queryParams.type ?? 'Toolsense',
											true,
											queryParams.site !== 'All' ? parseInt(queryParams.site!) : undefined,
											queryParams.rin?.includes('All') ? queryParams.rin : undefined,
											queryParams.model !== 'All' ? queryParams.model : undefined,
											queryParams.route !== 'All'
												? parseInt(queryParams.route!)
												: undefined,
											paging.pageNumber,
											paging.pageSize,
											paging.sortBy,
											paging.sortOrder
										);
									}
								};
							}}
						>
							<>
								{!isMobile && (
									<Table
										columns={origin === 'AMR' ? colonneAMR : colonneToolsense}
										type={origin === 'AMR' ? 'sitesRanking' : 'sitesRankingWithoutAction'}
										color={'green'}
										paging={true}
									/>
								)}
								{isMobile && (
									<TableMobile
										{...{
											columns: origin === 'AMR' ? colonneAMR : colonneToolsense,
											type:origin === 'AMR' ? 'sitesRanking' : 'sitesRankingWithoutAction',
											color: 'green'
										}}
									/>
								)}
							</>
						</PagingContextProvider>
					</div>
				</div>

				<div
					className={styles.table_container}
					style={underPerformingIsOpen ? {} : { display: 'none' }}
				>
					<div className={styles.table_title_header}>
						<TableTitle
							color="orange"
							titleText={t('sites.underPerforming')}
							// secondButtonText={t('sites.download')} secondButtonIcon={downloadIcon}
							titleIcon={underPerformingIcon}
						/>
						<DownloadButton
							name={!isMobile ? t('sites.download') : ''}
							className={styles.downloadBtn}
							iconStart={downloadIcon}
							filename={'less_performing_machines.csv'}
							columns={origin === 'AMR' ? colonneAMR : colonneToolsense}
							remoteMethod={() => {
								if (origin === 'AMR') {
									return CustomSitesPageResourceService.sitePageTableUnpaged(
										formDateToTimestamp(date1),
										formDateToTimestamp(date2, true),
										timezone,
										origin,
										false,
										site !== 'All' ? parseInt(site!) : undefined,
										multipleRin.includes('All') ? undefined : multipleRin,
										model !== 'All' ? model : undefined,
										route !== 'All' ? parseInt(route!) : undefined
									);
								} else {
									return CustomSitesPageResourceService.toolsenseSitePageTableUnpaged(
										formDateToTimestamp(date1),
										formDateToTimestamp(date2, true),
										timezone,
										origin,
										false,
										site !== 'All' ? parseInt(site!) : undefined,
										multipleRin.includes('All') ? undefined : multipleRin,
										model !== 'All' ? model : undefined,
										route !== 'All' ? parseInt(route!) : undefined
									);
								}
							}}
						/>
					</div>

					<div className={styles.table_subContainer}>
						<PagingContextProvider
							searchRef={buttonRef}
							queryParams={{
								date1,
								date2,
								type: origin,
								site,
								rin: multipleRin,
								model,
								route
							}}
							forceTrigger={forceOriginUpdate}
							triggerRemoteGet={true}
							remoteMethod={(queryParams, paging) => {
								return () => {
									if (origin === 'AMR') {
										return CustomSitesPageResourceService.sitePageTable(
											formDateToTimestamp(queryParams.date1!),
											formDateToTimestamp(queryParams.date2!, true),
											timezone,
											queryParams.type ?? 'AMR',
											false,
											queryParams.site !== 'All' ? parseInt(queryParams.site!) : undefined,
											queryParams.rin?.includes('All') ? queryParams.rin : undefined,
											queryParams.model !== 'All' ? queryParams.model : undefined,
											queryParams.route !== 'All'
												? parseInt(queryParams.route!)
												: undefined,
											paging.pageNumber,
											paging.pageSize,
											paging.sortBy,
											paging.sortOrder
										);
									} else {
										return CustomSitesPageResourceService.toolsenseSitePageTable(
											formDateToTimestamp(queryParams.date1!),
											formDateToTimestamp(queryParams.date2!, true),
											timezone,
											queryParams.type ?? 'Toolsense',
											false,
											queryParams.site !== 'All' ? parseInt(queryParams.site!) : undefined,
											queryParams.rin?.includes('All') ? queryParams.rin : undefined,
											queryParams.model !== 'All' ? queryParams.model : undefined,
											queryParams.route !== 'All'
												? parseInt(queryParams.route!)
												: undefined,
											paging.pageNumber,
											paging.pageSize,
											paging.sortBy,
											paging.sortOrder
										);
									}
								};
							}}
						>
							<>
								{!isMobile && (
									<Table
										columns={origin === 'AMR' ? colonneAMR : colonneToolsense}
										type={origin === 'AMR' ? 'sitesRanking' : 'sitesRankingWithoutAction'}
										color={'orange'}
										paging={true}
									/>
								)}
								{isMobile && (
									<TableMobile
										{...{
											columns: origin === 'AMR' ? colonneAMR : colonneToolsense,
											type:origin === 'AMR' ? 'sitesRanking' : 'sitesRankingWithoutAction',
											color: 'orange'
										}}
									/>
								)}
							</>
						</PagingContextProvider>
					</div>
				</div>
			</div>
		</>
	);
};
export default Sites;
