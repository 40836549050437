/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FiscalDataTotOutputDTO } from '../models/FiscalDataTotOutputDTO';
import type { PageConventionalDevTableOutputDTO } from '../models/PageConventionalDevTableOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomConventionalMachinesPageResourceService {

    /**
     * Get the computed total values of FiscalData of the specified Company
     * Retrieves the computed total values of FiscalData of the specified Company
     * @param companyId The company ID of user logged company
     * @param period
     * @returns FiscalDataTotOutputDTO OK
     * @throws ApiError
     */
    public static getFiscalDataTotalsOfACompany(
        companyId: string,
        period: string,
    ): CancelablePromise<FiscalDataTotOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/fiscalData-totals/{companyId}',
            path: {
                'companyId': companyId,
            },
            query: {
                'period': period,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get all Machines fiscal data of the specified Company
     * Retrieves a table of conventional machines of the specified Company
     * @param companyId The company ID of user logged company
     * @param period
     * @param pageNo
     * @param pageSize
     * @param sortBy
     * @param sortDir
     * @returns PageConventionalDevTableOutputDTO OK
     * @throws ApiError
     */
    public static getConventionalMachinesTableData(
        companyId: string,
        period: string,
        pageNo?: number,
        pageSize?: number,
        sortBy?: string,
        sortDir?: string,
    ): CancelablePromise<PageConventionalDevTableOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/fiscal-data-table/{companyId}',
            path: {
                'companyId': companyId,
            },
            query: {
                'period': period,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
