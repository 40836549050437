/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Benchmark } from './Benchmark';
import type { Contract } from './Contract';
import type { DeviceHistory } from './DeviceHistory';
import type { ManualUsage } from './ManualUsage';
import type { Notifications } from './Notifications';
import type { Routes } from './Routes';
import type { RoutesRunActivity } from './RoutesRunActivity';
import type { Sites } from './Sites';
import type { TrainingUsage } from './TrainingUsage';

export type Device = {
    id?: number;
    rin?: string;
    deviceMaker?: string;
    deviceModel?: string;
    scrubDeckWidth?: number;
    type?: string;
    serialNr?: string;
    name?: string;
    version?: string;
    warranStartDate?: string;
    deploymentDate?: string;
    rowUpdatedTimestamp?: string;
    dateCreation?: string;
    dateDeleted?: string;
    origin?: Device.origin;
    sites?: Sites;
    routes?: Array<Routes>;
    notifications?: Array<Notifications>;
    trainingUsages?: Array<TrainingUsage>;
    routesRunActivities?: Array<RoutesRunActivity>;
    manualUsages?: Array<ManualUsage>;
    benchmarks?: Array<Benchmark>;
    contracts?: Array<Contract>;
    deviceHistories?: Array<DeviceHistory>;
};

export namespace Device {

    export enum origin {
        BRAIN = 'Brain',
        TOOLSENSE = 'Toolsense',
        CONVENTIONAL = 'Conventional',
    }


}

