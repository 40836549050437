import { useRemoteGet, useWindowContext } from '@/hooks';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './ConventionalMachines.module.css';
import { useTranslation } from 'react-i18next';
import Box from '@/components/Box';
import Collapse from '@/components/UI/Collapse';
import Label from '@/components/UI/Label';
import InputField from '@/components/UI/InputField';
import AuthContext from '@/components/LoginForm/auth-context';
import { CustomConventionalMachinesPageResourceService } from '@/services/openapi';
import Button from '@/components/UI/Button';
import CloseIcon from '../../assets/images/closeIcon.svg';
import CustomLoader from '@/components/UI/CustomLoader';
import MainContext from '@/contexts/MainContext';
import Date from '@/components/UI/Date';
import { useFilterDateContext } from '@/hooks/FilterDateContext/useFilterDateContext';
import { formDateToTimestamp } from '@/utils';
import { PagingContextProvider } from '@/contexts/providers/PagingContextProvider';
import Table from '@/components/UI/Table/Table';
import TableMobile from '@/components/UI/Table/TableMobile';
import React from 'react';

const ConventionalMachines = () => {
	const { size, isMobile } = useWindowContext();
	const { t } = useTranslation();
	const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);

	const { date1, setDate1, timezone } = useFilterDateContext();
	const periodInputRef = useRef(null);

	const authCtx = useContext(AuthContext);

	const totalData = useRemoteGet({
		remoteMethod: () => {
			if (authCtx.companyID) {
				return CustomConventionalMachinesPageResourceService.getFiscalDataTotalsOfACompany(
					authCtx.companyID,
					formDateToTimestamp(date1, true)
				);
			}
		},
		lazy: true
	});

	useEffect(() => {
		if (!totalData.loading) totalData.fetch();
	}, []);

	const titleCtx = useContext(MainContext);
	const [titleLoaded, setTitleLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!titleLoaded) {
			if (totalData.data) {
				let devCounter = totalData.data.totMachines ?? 0;

				titleCtx.setTitle(
					<>
						<Label text={devCounter + ' '} className={styles.titleLabelBlue} />
						<Label
							text={t('sidebar.conventionalMachines')}
							className={styles.titleLabel}
						/>
					</>
				);
				setTitleLoaded(true);
			}
		}
	}, [totalData]);

	const buttonRef = useRef<HTMLButtonElement>(null);
	const applyFilters = () => {
		if (!totalData.loading) totalData.fetch();
	};

	const handleResetFilters = () => {};

	const loader = () => {
		if (totalData.loading)
			return (
				<span>
					<CustomLoader searchLoader={true} />
				</span>
			);
		else return <span></span>;
	};

	const colonne = {
		seriaN: t('conventionalLegend.columns.seriaN'),
		equipment: t('conventionalLegend.columns.equipment'),
		endUserName: t('conventionalLegend.columns.endUser'),
		site: t('conventionalLegend.columns.siteLocation'),
		dateOfMeasurement: t('conventionalLegend.columns.dateMeasurement'),
		engineHrsMeter: t('conventionalLegend.columns.engineHrsMts'),
		serviceContract: t('conventionalLegend.columns.serviceContract'),
		contractType: t('conventionalLegend.columns.contractType')
		// companyName: t('conventionalLegend.columns.customer')
	};

	const legendData = [
		{
			key: t('conventionalLegend.metrics.totMachine'),
			value: t('conventionalLegend.definitions.totMachine')
		},
		{
			key: t('conventionalLegend.metrics.bdVisits'),
			value: t('conventionalLegend.definitions.bdVisits')
		},
		{
			key: t('conventionalLegend.metrics.pmVisits'),
			value: t('conventionalLegend.definitions.pmVisits')
		},
		{
			key: t('conventionalLegend.metrics.totalCost'),
			value: t('conventionalLegend.definitions.totalCost')
		},
		{
			key: t('conventionalLegend.metrics.avoidableCost'),
			value: t('conventionalLegend.definitions.avoidableCost')
		},
		{
			key: t('conventionalLegend.metrics.response48h'),
			value: t('conventionalLegend.definitions.response48h')
		},
		{
			key: t('conventionalLegend.metrics.avgResHrs'),
			value: t('conventionalLegend.definitions.avgResHrs')
		},
		{
			key: t('conventionalLegend.metrics.avgHrsWorking'),
			value: t('conventionalLegend.definitions.avgHrsWorking')
		},
		{
			key: t('conventionalLegend.metrics.dbFirstTimeFix'),
			value: t('conventionalLegend.definitions.dbFirstTimeFix')
		},
		{
			key: t('conventionalLegend.metrics.percMachineWorking'),
			value: t('conventionalLegend.definitions.percMachineWorking')
		},
		{
			key: t('conventionalLegend.metrics.pmCompleted'),
			value: t('conventionalLegend.definitions.pmCompleted')
		},
		{
			key: t('conventionalLegend.metrics.percPm'),
			value: t('conventionalLegend.definitions.percPm')
		},
		{
			key: t('conventionalLegend.metrics.jobsAvoidCost'),
			value: t('conventionalLegend.definitions.jobsAvoidCost')
		},
		{
			key: t('conventionalLegend.metrics.percJobsAvoidCost'),
			value: t('conventionalLegend.definitions.percJobsAvoidCost')
		},
		{
			key: t('conventionalLegend.metrics.percFleetNotifs'),
			value: t('conventionalLegend.definitions.percFleetNotifs')
		}
	];

	const getDefinition = (label: string) => {
		const item = legendData.find((data) => data.key === label);
		return item ? item.value : '';
	};

	return (
		<>
			<div className={styles.container}>
				{isMobile /* --------------------- Header Mobile --------------------- */ && (
					<div className={styles.title_container}>
						<Label
							text={totalData.data?.totMachines?.toString() ?? '0 '}
							className={styles.title_labelColored}
						/>
						<Label text={t('sidebar.conventionalMachines')} className={''} />
					</div>
				)}
				<div className={styles.subHeader_container}>
					<Label
						text={totalData.data?.customerName ?? ''}
						className={styles.subHeader_labelColored}
					/>
					<Label
						text={t('conventionalLegend.columns.customer').toLowerCase()}
						className={''}
					/>
				</div>

				{/* ----------------------- Filters ----------------------- */}
				{!isMobile && (
					<div className={styles.filterbar_main_container}>
						<div className={styles.filterBar_container}>
							<div className={styles.filterBar_header}>
								<Label text={t('AmrMachines.filters')} className={styles.filterLabel} />
								<Button
									name={t('AmrMachines.resetFilters')}
									className={styles.resetFilters_btn}
									iconStart={CloseIcon}
									iconStartClassName={styles.resetFiltersIcon}
									onClick={handleResetFilters}
								/>
							</div>

							<div className={styles.filterBar_body}>
								<div className={styles.labelWithIcon}>
									<Label
										text={t('conventionalLegend.machinesPage.filters')}
										className={styles.dateFilterLabel}
									/>
									<div className={styles.iconWithTooltip}>
										<span className={styles.infoIcon}>i</span>
										<span className={styles.tooltipText}>
											{t('conventionalLegend.machinesPage.moreInfo')}
										</span>
									</div>
								</div>
								<Date
									id="period"
									name="fiscalPeriod"
									value={date1}
									min="string"
									max="string"
									ref={periodInputRef}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setDate1(e.target.value);
									}}
								/>
							</div>

							<div className={styles.searchBtn_container}>
								<Button
									ref={buttonRef}
									name={t('dashboard.applyFilters')}
									className={styles.searchBtn}
									onClick={applyFilters}
								/>
								{loader()}
							</div>
						</div>
					</div>
				)}

				{/* ----------------------- Total data ------------------------ */}
				<Collapse title={t('machine.title')}>
					<Box
						className={styles.box_container}
						children={
							<div className={styles.box_subContainer}>
								{!isMobile && (
									<Label text={t('machine.details')} className={styles.titleLabel} />
								)}
								<div className={styles.inputContainer}>
									<InputField
										type={'text'}
										placeholder={
											t('conventionalLegend.machinesPage.completedJobs') + ' PMs'
										}
										title={''}
										required={false}
										label={t('conventionalLegend.metrics.pmVisits')}
										labelClassName={styles.label}
										value={totalData.data?.totPMs?.toString() ?? ''}
										disabled={true}
										showInfo={true}
										moreInfoText={getDefinition(t('conventionalLegend.metrics.pmVisits'))}
									/>
									<InputField
										type={'text'}
										placeholder={
											t('conventionalLegend.machinesPage.completedJobs') + ' BDs'
										}
										title={''}
										required={false}
										label={t('conventionalLegend.metrics.bdVisits')}
										labelClassName={styles.label}
										value={totalData.data?.totBDs?.toString() ?? ''}
										disabled={true}
										showInfo={true}
										moreInfoText={getDefinition(t('conventionalLegend.metrics.bdVisits'))}
									/>
									<InputField
										type={'text'}
										placeholder={t('conventionalLegend.machinesPage.ftfPercentage')}
										title={''}
										required={false}
										label={t('conventionalLegend.machinesPage.ftfPercentage')}
										labelClassName={styles.label}
										value={totalData.data?.totPercFTF?.toString() + '%' ?? ''}
										disabled={true}
									/>
								</div>
								<div className={styles.inputContainer}>
									<InputField
										type={'text'}
										placeholder={t('conventionalLegend.machinesPage.pmPercInMonth')}
										title={''}
										required={false}
										label={t('conventionalLegend.machinesPage.pmPercInMonth')}
										labelClassName={styles.label}
										value={totalData.data?.totPercPMsInMonth?.toString() + '%' ?? ''}
										disabled={true}
									/>
									<InputField
										type={'text'}
										placeholder={t('conventionalLegend.machinesPage.percRT24hrs')}
										title={''}
										required={false}
										label={t('conventionalLegend.machinesPage.percRT24hrs')}
										labelClassName={styles.label}
										value={totalData.data?.totPercRT24?.toString() + '%' ?? ''}
										disabled={true}
									/>
									<InputField
										type={'text'}
										placeholder={t('conventionalLegend.machinesPage.percRT48hrs')}
										title={''}
										required={false}
										label={t('conventionalLegend.machinesPage.percRT48hrs')}
										labelClassName={styles.label}
										value={totalData.data?.totPercRT48?.toString() + '%' ?? ''}
										disabled={true}
									/>
								</div>
							</div>
						}
					/>
				</Collapse>

				{/* ----------------------- Data Legend ----------------------- */}
				{/* <div className={styles.main_legendContainer}>
					<div className={styles.legendContainer}>
						<div className={styles.gridLegend}>
							<div className={styles.headerLegendRow}>
								{t('conventionalLegend.metrics.metric')}
							</div>
							<div className={styles.headerLegendRow}>
								{t('conventionalLegend.definitions.definition')}
							</div>
							<div className={styles.headerLegendRow}>
								{t('conventionalLegend.metrics.metric')}
							</div>
							<div className={styles.headerLegendRow}>
								{t('conventionalLegend.definitions.definition')}
							</div>
							{legendData.map((item, index) => (
								<React.Fragment key={item.key}>
									<div className={styles.metricLabel}>{item.key}</div>
									<div>{item.value}</div>
								</React.Fragment>
							))}
						</div>
					</div>
				</div> */}

				{/* ----------------------- Conventional Machines ------------------------ */}
				<div className={styles.table_container}>
					<PagingContextProvider
						searchRef={buttonRef}
						queryParams={{ companyId: authCtx.companyID, date1 }}
						remoteMethod={(queryParams, paging) => {
							return () => {
								return CustomConventionalMachinesPageResourceService.getConventionalMachinesTableData(
									queryParams.companyId ?? '',
									formDateToTimestamp(queryParams.date1!),
									paging.pageNumber,
									paging.pageSize,
									paging.sortBy,
									paging.sortOrder
								);
							};
						}}
					>
						<>
							{!isMobile && (
								<Table
									columns={colonne}
									type={'conventionalRanking'}
									color={'blue'}
									paging={true}
								/>
							)}
							{isMobile && (
								<TableMobile
									{...{ columns: colonne, type: 'conventionalRanking', color: 'blue' }}
								/>
							)}
						</>
					</PagingContextProvider>
				</div>
			</div>
		</>
	);
};

export default ConventionalMachines;
